import React from "react";
import { FooterStyled } from "./_components";
import logo from "../../assets/mn-logo.svg";
import { Button } from "antd";
import booksy from "../../assets/booksy.svg";
import InstagramOutlined from "@ant-design/icons/lib/icons/InstagramOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";

const Footer = () => {
  return (
    <FooterStyled>
      <div className="ant-footer-wrapper">
        <div>
          <div className="ant-footer-container">
            <p>Informacje:</p>
            <span>tel.: 726 701 499</span>
            <span>martanagorska.mm@gmail.com</span>
          </div>
          <div className="ant-footer-container">
            <p>Adres:</p>
            <span>Rotmanka,</span>
            <span>ul. Topolowa 5</span>
          </div>
        </div>
        <div>
          <div className="logo-wrapper">
            <img src={logo} alt="" />
            <div>
              <span>Kosmetolog</span>
              <span>Marta Nagórska</span>
            </div>
          </div>
          <div className="ant-footer-icon-wrapper">
            <a
              href="https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                type="text"
                style={{
                  padding: "4px 6px",
                  color: "black",
                  backgroundColor: "transparent"
                }}
              >
                <img src={booksy} alt="" />
              </Button>
            </a>
            <a
              href="https://www.instagram.com/makijazemarty/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                type="text"
                style={{ color: "black", backgroundColor: "transparent" }}
                icon={<InstagramOutlined />}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.facebook.com/MakijazeMarty"
            >
              <Button
                type="text"
                style={{ color: "black", backgroundColor: "transparent" }}
                icon={<FacebookOutlined />}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="copyrights">
        <span>Copyright © 2021 MakijażeMarty</span>
        <a target="_blank" rel="noopener noreferrer" href="http://avnr.website">
          {"</> by Marcin Bieszka"}
        </a>
      </div>
    </FooterStyled>
  );
};

export default Footer;
