import React from "react";
import { JumbotronStyled } from "./_components";
import Banner from "../../assets/banner.jpg";

const Jumbotron = () => {
  return (
    <JumbotronStyled>
      <img src={Banner} alt="" />
      <h1>Odkryj swoją urodę na nowo</h1>
    </JumbotronStyled>
  );
};

export default Jumbotron;
