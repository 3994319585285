import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Drawer} from "antd";
import MobileContainer from "./MobileContainer";
import {HeaderStyled} from "./_components";
import {MenuOutlined} from "@ant-design/icons";
import Logo from "../../../src/assets/mn-logo.svg";
import InstagramOutlined from "@ant-design/icons/lib/icons/InstagramOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import booksy from "../../assets/booksy.svg";
import * as classnames from "classnames";

class Header extends Component {
  state = { visible: false, booksy: true };

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const booksy = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      booksy
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <HeaderStyled className="Navigation">
        <div
          className={classnames("ant-header-wrapper", {
            "ant-header-wrapper-hidden": !this.state.booksy
          })}
        >
          <Link
            to="/"
            className={classnames("logo-name", {
              "logo-name-hidden": !this.state.booksy
            })}
          >
            <img src={Logo} alt="" />
          </Link>

          <div className="ant-left-content">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
              }
            >
              Zabiegi
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
              }
            >
              Cennik
            </a>
          </div>
          <Drawer
            className="menu"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <MobileContainer />
          </Drawer>
          <div className="ant-right-content">
            <a
              href="https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                type="text"
                style={{
                  padding: "4px 6px",
                  color: "black",
                  backgroundColor: "transparent"
                }}
              >
                <img src={booksy} alt="" />
              </Button>
            </a>
            <a
              href="https://www.instagram.com/makijazemarty/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                type="text"
                style={{ color: "black", backgroundColor: "transparent" }}
                icon={<InstagramOutlined />}
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.facebook.com/MakijazeMarty"
            >
              <Button
                type="text"
                style={{ color: "black", backgroundColor: "transparent" }}
                icon={<FacebookOutlined />}
              />
            </a>
          </div>
          <Button
            className="menu"
            type="text"
            icon={<MenuOutlined />}
            onClick={this.showDrawer}
          />
        </div>
      </HeaderStyled>
    );
  }
}

export default Header;
