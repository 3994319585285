import styled from "styled-components";

export const ResponsiveWrapperStyled = styled.div`
  width: 100%;
  max-width: 1480px;
`;

export const CustomLinkStyled = styled.div`
  position: relative;
  margin-left: 1.5em;
  display: inline-block;
  a {
    position: relative;
    white-space: nowrap;
    z-index: 100;
    color: rgba(49, 70, 89, 0.85);
  }

  svg {
    transition: 200ms ease-out;
    position: relative;
    top: 0.25em;
    left: 1.5em;
    stroke: rgba(49, 70, 89, 0.85);
  }

  &:after {
    transition: 200ms ease-out;
    position: absolute;
    z-index: 0;
    content: "";
    width: 3em;
    left: -1.5em;
    border-radius: 1.5em;
    height: 3em;
    top: -0.55em;
    background-color: rgba(49, 70, 89, 0.15);
  }

  &:hover {
    &:after {
      width: calc(100% + 3.5em);
      background-color: rgba(49, 70, 89, 0.05);
    }

    svg {
      left: 0.75em;
      z-index: 1;
      stroke: rgba(49, 70, 89, 0.85);
    }
  }
`;
