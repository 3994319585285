import React from "react";
import Jumbotron from "../components/jumbotron/Jumbotron";
import About from "../components/sections/aboutme/About";
import Clients from "../components/sections/clients/Clients";

const Home = () => {
  return (
    <div>
      <Jumbotron />
      <About />
      <Clients />
    </div>
  );
};

export default Home;
