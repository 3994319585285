import styled from "styled-components";

export const JumbotronStyled = styled.div`
  position: relative;
  margin-top: 7em;
  overflow: hidden;
  height: 60vh;
  max-height: 50em;
  min-height: 30em;
  z-index: 0;
  img {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
  }
  h1 {
    position: absolute;
    display: block;
    width: 41vw;
    top: 50%;
    left: 50%;
    font-size: 3vw;
    font-weight: 300;
    color: #607284;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1024px) {
    margin-top: 4em;
    height: 90vh;
    min-height: 28em;
    img {
      width: auto;
      height: 100%;
    }
    h1 {
      font-size: 2.4em;
      width: 100%;
      max-width: 15em;
    }
  }

  @media (max-width: 530px) {
    h1 {
      top: 40%;
      font-size: 1.9em;
      max-width: 14em;
    }
  }
`;
