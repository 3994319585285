import styled from "styled-components";

export const AboutMeContainerStyled = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 79%;
  padding: 4em 0;
  min-width: calc(1024px - 4em);
  max-width: 1480px;
  margin: 0 2em;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
    min-width: 0;
  }

  @media (max-width: 530px) {
    width: calc(100% - 2em);
    margin: 0 1em;
  }
`;

export const LeftContainerStyled = styled.div`
  text-align: left;
  max-width: 40em;

  h1 {
    font-size: 2.5em;
    color: #506172;
  }

  p {
    color: #506172;
    text-align: left;
    font-size: 1.25em;
    &:nth-of-type(3) {
      margin-bottom: 3em;
    }
  }

  @media (max-width: 530px) {
    p {
      text-align: justify;
    }
  }
`;

export const RightContainerStyled = styled.div`
  width: 35%;
  z-index: 1;
  img {
    width: 100%;
  }

  @media (max-width: 1024px) {
    margin-top: -10em;
    width: 90%;
    max-width: 30em;
    margin-bottom: 2em;
    img {
      border-radius: 0.75em;
      box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 624px) {
    width: 100%;
  }

  @media (max-width: 530px) {
    max-width: none;
  }
`;
