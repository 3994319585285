import React from "react";
import RightContainer from "./RightContainer";
import LeftContainer from "./LeftContainer";
import { AboutMeContainerStyled } from "./_components";

const About = () => {
  return (
    <AboutMeContainerStyled>
      <LeftContainer />
      <RightContainer />
    </AboutMeContainerStyled>
  );
};

export default About;
