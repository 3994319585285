import React from "react";
import { Layout } from "antd";

const Contact = () => {
  return (
    <Layout>
      <></>
    </Layout>
  );
};

export default Contact;
