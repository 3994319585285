import Ada from "../../../assets/carousel/Ada.jpg";
import Ada1 from "../../../assets/carousel/Ada1.jpg";
import Ada2 from "../../../assets/carousel/Ada2.jpg";
import Ada3 from "../../../assets/carousel/Ada3.jpg";
import Aga from "../../../assets/carousel/Aga.jpg";
import Alicja from "../../../assets/carousel/Alicja.jpg";
import Ania from "../../../assets/carousel/Ania.jpg";
import Jadzia1 from "../../../assets/carousel/Jadzia1.jpg";
import Jadzia2 from "../../../assets/carousel/Jadzia2.jpg";
import Jadzia3 from "../../../assets/carousel/Jadzia3.jpg";
import Justyna from "../../../assets/carousel/Justyna.jpg";
import Justyna1 from "../../../assets/carousel/Justyna1.jpg";
import Kasia1 from "../../../assets/carousel/Kasia1.jpg";
import Kasia2 from "../../../assets/carousel/Kasia2.jpg";
import Kasia3 from "../../../assets/carousel/Kasia3.jpg";
import KasiaK1 from "../../../assets/carousel/KasiaK1.jpg";
import KasiaK3 from "../../../assets/carousel/KasiaK3.jpg";
import KatiaK2 from "../../../assets/carousel/KatiaK2.jpg";
import KatiaK4 from "../../../assets/carousel/KatiaK4.jpg";
import Ktos from "../../../assets/carousel/Ktos.jpg";
import Malgosia from "../../../assets/carousel/Malgosia.jpg";
import Mod from "../../../assets/carousel/Mod.jpg";
import Natalia1 from "../../../assets/carousel/Natalia1.jpg";
import Natalia2 from "../../../assets/carousel/Natalia2.jpg";
import Patrycja1 from "../../../assets/carousel/Patrycja1.jpg";
import Patrycja2 from "../../../assets/carousel/Patrycja2.jpg";
import Patrycja3 from "../../../assets/carousel/Patrycja3.jpg";
import Sara1 from "../../../assets/carousel/Sara1.jpg";
import Sara2 from "../../../assets/carousel/Sara2.jpg";
import Ula1 from "../../../assets/carousel/Ula1.jpg";
import Valentina from "../../../assets/carousel/Valentina.jpg";
import Valentina2 from "../../../assets/carousel/Valentina2.jpg";

export const CarouselImages = [
  {
    source: Ada
  },
  {
    source: Ada1
  },
  {
    source: Ada2
  },
  {
    source: Ada3
  },
  {
    source: Aga
  },
  {
    source: Alicja
  },
  {
    source: Ania
  },
  {
    source: Jadzia1
  },
  {
    source: Jadzia2
  },
  {
    source: Jadzia3
  },
  {
    source: Justyna
  },
  {
    source: Justyna1
  },
  {
    source: Kasia1
  },
  {
    source: Kasia2
  },
  {
    source: Kasia3
  },
  {
    source: KasiaK1
  },
  {
    source: KasiaK3
  },
  {
    source: KatiaK2
  },
  {
    source: KatiaK4
  },
  {
    source: Ktos
  },
  {
    source: Malgosia
  },
  {
    source: Mod
  },
  {
    source: Natalia1
  },
  {
    source: Natalia2
  },
  {
    source: Patrycja1
  },
  {
    source: Patrycja2
  },
  {
    source: Patrycja3
  },
  {
    source: Sara1
  },
  {
    source: Sara2
  },
  {
    source: Ula1
  },
  {
    source: Valentina
  },
  {
    source: Valentina2
  }
];
