import React from "react";
import { LeftContainerStyled } from "./_components";
import CustomLink from "../../../CustomLink";

const LeftContainer = () => {
  return (
    <LeftContainerStyled>
      <h1>O mnie</h1>
      <p>
        Zapraszam Cię do mojego kosmetycznego miejsca w Rotmance, które powstało
        z myślą o waszym komforcie. Oferuję zabiegi z zakresu kosmetologii,
        stylizacji brwi i rzęs oraz makijażu okazjonalnego. W branży beauty
        pracuję od 2012 roku i moje doświadczenie obejmuje pracę w tradycyjnych
        salonach kosmetycznych, ośrodkach spa jak i klinice kosmetologii.
        Postanowiłam iść własną drogą i wyspecjalizować się w kilku ulubionych
        dziedzinach w celu oferowania najwyżej jakości.
      </p>
      <p>
        Podczas zabiegów korzystam z oryginalnych produktów z zachowaniem
        najwyższych standardów higieny.
      </p>
      <p>
        Staram się do moich klientów podchodzić indywidualnie, ponieważ każda
        skóra oraz oczekiwania są inne. Dokładam wszelkich starań, by czas
        spędzony w moim salonie był dla Państwa miłym doświadczeniem, a przede
        wszystkim by był efektywny.
      </p>

      <CustomLink
        link="https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
        title="Umów wizytę"
      />
    </LeftContainerStyled>
  );
};

export default LeftContainer;
