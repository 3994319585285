import styled from "styled-components";

export const HeaderStyled = styled.div`
  display: flex;
  width: 100%;
  z-index: 110;
  //box-shadow: 0 0 15px 0 black;
  position: fixed;
  background-color: white;
  align-content: center;
  place-content: center;
  align-items: center;

  & .menu {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  & .ant-header-wrapper {
    //border: 1px solid red;
    position: relative;
    display: flex;
    width: 79%;
    align-items: center;
    min-width: calc(1024px - 4em);
    max-width: 1480px;
    height: 8em;
    flex-direction: row;
    justify-content: space-between;
    transition: 600ms cubic-bezier(0.65, 0, 0.35, 1);

    @media (max-width: 1025px) {
      justify-content: space-between;
      width: calc(100% - 4em);
      min-width: 0;
      height: 4em;
    }

    @media (max-width: 530px) {
      width: calc(100% - 2em);
    }
  }

  & .ant-header-wrapper-hidden {
    height: 6em;

    @media (max-width: 1025px) {
      height: 4em;
    }
  }

  & .ant-left-content {
    a {
      color: #506172;
      margin: 0 0.75em;
      padding: 0.5em;

      &:nth-of-type(1) {
        margin-left: 0;
        padding-left: 0;
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }

  & .ant-right-content {
    button {
      img {
        width: 18px;
        margin-top: -2px;
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }

  & .logo-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:after {
      transition: 300ms ease-out;
      color: black;
      content: "Marta Nagórska";
      position: absolute;
      white-space: nowrap;
      text-align: center;
      left: 50%;
      transform: translate(-50%);
      bottom: -50%;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    @media (max-width: 1024px) {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
      img {
        max-height: 3em;
      }
    }
  }

  & .logo-name-hidden {
    &:after {
      transition: 300ms ease-out;
      color: transparent;
      content: "Marta Nagórska";
      position: absolute;
      white-space: nowrap;
      text-align: center;
      left: 50%;
      transform: translate(-50%);
      bottom: 0%;
    }
  }

  @media (max-width: 1024px) {
  }
`;

export const MobileContainerStyled = styled.div`
  & .ant-header-menu-wrapper {
    text-align: center;
    border-bottom: 1px solid gray;
    margin-bottom: 3em;
    padding-bottom: 1em;
    button {
      margin: 0.5em;
      img {
        width: 24px;
        margin-top: -2px;
      }
    }
  }
  a {
    color: #506172;
    margin: 1em 0;
    padding: 0.5em 0;
    display: block;
    text-align: center;
  }

  & .anticon-icon {
    display: inline-block;
  }
`;
