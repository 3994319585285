import styled from "styled-components";

export const FooterStyled = styled.div`
  & .ant-footer-wrapper {
    padding-top: 5em;
    padding-bottom: 3em;
    position: relative;
    display: flex;
    width: 79%;
    min-width: calc(1024px - 4em);
    max-width: 1480px;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1025px) {
      justify-content: space-between;
      width: calc(100% - 4em);
      min-width: 0;
    }

    @media (max-width: 400px) {
      flex-direction: column;
    }

    @media (max-width: 530px) {
      width: calc(100% - 2em);
      flex-direction: column-reverse;
      align-items: center;
      padding-bottom: 1em;
    }
  }

  & .ant-footer-container {
    display: inline-block;
    text-align: left;
    min-width: 24em;

    @media (max-width: 360px) {
      min-width: 0;
      display: block;
      margin-bottom: 1em;
      text-align: center;
    }

    p {
      font-weight: 700;
      @media (max-width: 360px) {
        margin-bottom: 0;
      }
    }

    span {
      margin: 0.25em 0;
      display: block;
    }

    @media (max-width: 900px) {
      min-width: 15.5em;

      &:nth-of-type(2) {
        min-width: 0;
      }
    }
  }

  & .ant-footer-icon-wrapper {
    button {
      margin: 0.5em;
      img {
        width: 24px;
        margin-top: -2px;
      }
    }
    a {
      &:nth-of-type(1) {
        button {
          margin-left: -1em;
        }
      }
    }
  }

  & .logo-wrapper {
    display: flex;
    align-items: center;

    img {
      height: 2.5em;
    }
    div {
      margin-left: 0.75em;
      text-align: left;
      display: inline-block;
      span {
        white-space: nowrap;
        display: block;
      }
    }

    @media (max-width: 530px) {
      margin-bottom: 1em;
    }
  }

  & .copyrights {
    display: inline-block;
    margin: 2em 0;
    a,
    span {
      font-size: 0.9em;
      color: #8c8c8c;
      display: block;
    }
  }
`;
