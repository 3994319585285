import React, {Component} from "react";
import {CustomLinkStyled} from "./components/_maincomponents";

const CustomArrow = () => (
  <svg width="36" height="14" viewBox="0 0 36 14" fill="none">
    <path xmlns="http://www.w3.org/2000/svg" d="M0 7L35 7" />
    <path xmlns="http://www.w3.org/2000/svg" d="M29 1L35 7.28571L29 13" />
  </svg>
);

class CustomLink extends Component {
  render() {
    return (
      <CustomLinkStyled>
        <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
          {this.props.title}
        </a>
        <CustomArrow color="orange" />
      </CustomLinkStyled>
    );
  }
}

export default CustomLink;
