import styled from "styled-components";

export const ClientsContainerStyled = styled.div`
  width: 100%;
  background-color: #f8f9fa;
  overflow: hidden;
`;

export const ClientsWrapperStyled = styled.div`
  width: 79%;
  padding: 4em 0;
  min-width: calc(1024px - 4em);
  max-width: 1480px;
  text-align: left;
  h1 {
    font-size: 2.5em;
    color: #506172;
  }

  div {
    div {
      & .slick-track {
        display: flex;
        align-items: center;

        & .slick-slide {
          //min-height: 20em;
          img {
          }
        }
      }
    }
  }

  & .slick-slide {
    padding: 0 calc(2vh - 0.1em);

    @media (max-width: 1024px) {
    }
    img {
      width: 100%;
    }
  }

  @media (max-width: 1025px) {
    width: calc(100% - 4em);
    min-width: 0;
  }

  @media (max-width: 530px) {
    width: calc(100% - 2em);
  }
`;
