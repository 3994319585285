import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ClientsContainerStyled, ClientsWrapperStyled } from "./_components";
import { CarouselImages } from "./Images";

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 2,
  autoplay: false,
  draggable: true,
  autoplaySpeed: 2500,
  //autoplaySpeed: 90000,
  pauseOnHover: true,
  speed: 400,
  cssEase: "cubic-bezier(0.65, 0, 0.35, 1)",
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Clients = () => {
  return (
    <ClientsContainerStyled>
      <ClientsWrapperStyled>
        <h1>Klientki, które zaufały:</h1>
        <Slider {...settings}>
          {Object.keys(CarouselImages).map(obj => {
            return (
              <>
                <img src={CarouselImages[obj].source} alt="" />
              </>
            );
          })}
        </Slider>
      </ClientsWrapperStyled>
    </ClientsContainerStyled>
  );
};

export default Clients;
