import React from "react";
import Face from "../../../assets/martanagorska.webp";
import {RightContainerStyled} from "./_components";

const RightContainer = () => {
  return (
    <RightContainerStyled>
      <img src={Face} alt="" />
    </RightContainerStyled>
  );
};

export default RightContainer;
