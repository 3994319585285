import React from "react";
import {MobileContainerStyled} from "./_components";
import {Button} from "antd";
import booksy from "../../assets/booksy.svg";
import InstagramOutlined from "@ant-design/icons/lib/icons/InstagramOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";

const MobileContainer = () => {
  return (
    <MobileContainerStyled>
      <div className="ant-header-menu-wrapper">
        <a
          className="anticon-icon"
          href="https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button
            type="text"
            style={{
              padding: "4px 6px",
              color: "black",
              backgroundColor: "transparent"
            }}
          >
            <img src={booksy} alt="" />
          </Button>
        </a>
        <a
          className="anticon-icon"
          href="https://www.instagram.com/makijazemarty/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            type="text"
            style={{ color: "black", backgroundColor: "transparent" }}
            icon={<InstagramOutlined />}
          />
        </a>
        <a
          className="anticon-icon"
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.facebook.com/MakijazeMarty"
        >
          <Button
            type="text"
            style={{ color: "black", backgroundColor: "transparent" }}
            icon={<FacebookOutlined />}
          />
        </a>
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          "https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
        }
      >
        Zabiegi
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          "https://booksy.com/pl-pl/72636_kosmetolog-marta-nagorska-makijazemarty_salon-kosmetyczny_21804_pruszcz-gdanski"
        }
      >
        Cennik
      </a>

    </MobileContainerStyled>
  );
};

export default MobileContainer;
