import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Header from "./components/header/Header";
import "antd/dist/antd.css";
import { Layout } from "antd";
import Footer from "./components/footer/Footer";

const App = () => {
  return (
    <Router>
      <Layout>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </Layout>
    </Router>
  );
};

export default App;
